import { createContext, useEffect, useState, useMemo } from "react";

import { Experiment } from "@amplitude/experiment-js-client";

import { useUserDataStore } from "@/store/UserDataStore";

type variants = Record<string, "treatment" | "control" | "off" | "on">;

type ExperimentContext = {
    loading: boolean,
    variants: variants
}

type ExperimentItem = {
    key: string;
    user_id?: string;
}

export const AmplitudeExperimentContext = createContext<ExperimentContext>({
    loading: true,
    variants: {}
});


export const getAmplitudeExperiment = async (variantKey: string, user_id?: string) => {
    const experiment = Experiment?.initializeWithAmplitudeAnalytics(
        process.env.NEXT_PUBLIC_AMP_EXP_DEPLOY || "",
        {
            automaticFetchOnAmplitudeIdentityChange: true,
        }
    );

    user_id ? await experiment?.start({ user_id: user_id }) : await experiment?.start()

    /**
     * If using user_id for experiments for specific users, use the fetch method
     * to get most up to date variants. Prevents need for a page refresh if user changes
     * e.g switching between sales person
     */

    const userExperiment = user_id
        ? await experiment.fetch({ user_id })
        : null

    const getVariant = () => {
        return userExperiment
            ? userExperiment.variant(variantKey).value
            : experiment.variant(variantKey).value;
    }

    return getVariant;
}

export const ExperimentsProvider = ({ children }: Record<string, any>) => {

    const [experiments, setExperiments] = useState<variants>({});
    const [experimentsLoading, setExperimentsLoading] = useState(true);

    const [user] =
        useUserDataStore((state) => [
            state.user,
        ]);

    // Add experiments here, id optional if experiment is used on particular cohort/id's
    const experimentsToFetch: ExperimentItem[] = [
        {
            key: "tap-to-capture",
            user_id: `${user.sales_person_id}`
        }
    ]

    const fetchExperiments = async () => {

        setExperimentsLoading(true);

        const result: variants = {};

        await Promise.all(
            experimentsToFetch.map(async (experiment) => {
                const getVariant = await getAmplitudeExperiment(experiment.key, experiment.user_id);
                // create getters, so we can still access the property in the same way, but the event won't run until it's called
                Object.defineProperty(result, experiment.key, {
                    get() {
                        return getVariant();
                    }
                });
            })
        );
        setExperiments(result);
        setExperimentsLoading(false);
    }

    useEffect(() => {

        fetchExperiments();

    }, [user.sales_person_id]);

    const experimentsContext = useMemo(() => ({
        loading: experimentsLoading,
        variants: experiments,
    }), [experiments, experimentsLoading]);

    console.log(experiments)

    return (
        <AmplitudeExperimentContext.Provider value={experimentsContext}>
            {children}
        </AmplitudeExperimentContext.Provider>
    )
};